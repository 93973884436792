<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2023-09-03 00:50:21
 * @FilePath: \personal\src\views\text\UploadModal.vue
 * @Description: 提示 
-->
<template>
<div>
  <a-modal
    class="upload-modal"
    :width="540"
    title="文件上传"
    :visible="visible"
    :maskClosable="false"
    @cancel="close"
  >
    <template slot="footer">
      <div class="button">
        <div @click="close" class="cancle">取消</div>
        <div @click="handleUpload" class="ok">确定</div>
      </div>
    </template>
    <div class="modal-content">
      <a-upload-dragger
        name="file"
        accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain, text/xml, application/xml"
        :multiple="true"
        :remove="handleRemove"
        :before-upload="beforeUpload"
        :fileList="fileList"
      >
        <div class="upload-drag">
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <div>
            <p class="ant-upload-text">
            点击或将文件拖拽到这里上传
            </p>
            <p class="ant-upload-hint">
              支持扩展名：.doc .docx .txt .xml <br />
              单个文件大小：{{limit}} MB以下 <br />
              文件名长度：60字及以下
            </p>
          </div>
        </div>
      </a-upload-dragger>
      <div style="color: red;text-align: center;margin-top: 10px;">温馨提示：文档中的复杂格式和样式无法还原。</div>
    </div>
    <TipsModal v-if="flagVisible" tipsType="tipsSeven" :visible="flagVisible" :cur="cur" :beyond="beyond" buttonText="我知道了" :handleClose="() => flagVisible = false" />

    <!-- ocr弹窗 -->
    <HintOrDownModal
      v-if="ocrVisible"
      :visible="ocrVisible"
      :handleClose="handleOcrClose"
      :handleCallback="handleOcrCallBack"
      tipsType="tipsTen"
    />
  </a-modal>
</div>
  
</template>

<script>
import { uploadFile, queryMaxBookStorageLimit, limitImage } from '@/api/ocr';
import TipsModal from '@/components/TipsModal.vue';
import HintOrDownModal from '@/components/HintOrDownModal.vue';

export default {
  components: {
    TipsModal: TipsModal,
    HintOrDownModal: HintOrDownModal
  },
  props: [
    'visible',
    'handleClose',
    'handleOk',
  ],
  data() {
    return {
      fileList: [],
      copyFileList: [],
      // uploading: false,
      limit: '',
      tipsText: '', // 错误提示
      flagVisible: false,
      cur: 0,
      beyond: 0,
      ocrVisible: false,
    }
  },
  async mounted() {
    const res = await limitImage({})
    this.limit = res.data.maxBookStorageLimitToMB
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      let tipsText = undefined
      if (file.name.length > 65) {
        tipsText = '超出文件名长度, 请重新命名后, 重新上传。'
      }
      const isLt2M = (file.size / 1024) < 1024 * this.limit;
      if (!isLt2M) {
        tipsText = '超出单个文件大小, 请分多个文件上传, 重新上传。'
      }

      // 获取文件的扩展名
      var extension = file.name.split('.').pop().toLowerCase();
      // 定义允许的文件格式数组
      var allowedExtensions = ['doc', 'docx', 'txt', 'xml'];
      
      // 检查文件格式是否在允许的数组中
      if (allowedExtensions.indexOf(extension) === -1) {
        tipsText = '暂不支持该扩展名, 请转换格式后, 重新上传。'
      }
      const newFile = {
        ...file,
        size: file.size / 1024,
        status: tipsText ? 'error' : 'done',
        name: !tipsText ? <span>{ file.name }</span> : <div style="height: 45px"> {file.name} <br/> {tipsText}</div> 
      }
      this.copyFileList = [...this.copyFileList, file]
      this.fileList = [...this.fileList, newFile];
      return false;
    },
    async handleUpload() {
      if (!this.fileList.length) return this.$message.error('请先上传文件');
      const flag = this.fileList.every((item) => item.status === 'done');
      if (!flag) return  this.$message.error('您要上传的文件中存在不符合要求的文件, 请检查后重新上传');
      const res = await queryMaxBookStorageLimit({});
      const { maxBookStorageLimit = 0 } = res;
      const newMax = maxBookStorageLimit ? Number(maxBookStorageLimit.split('M')[0]) ?? 0 : 0;
      if (maxBookStorageLimit && newMax <  this.fileList.reduce((p, c) => p + c.size, 0)) {
        this.cur = newMax;
        this.beyond = this.fileList.reduce((p, c) => p + c.size, 0) - newMax;
        this.flagVisible = true
        return 
      }
      this.ocrVisible = true
      const { copyFileList } = this;
      const formData = new FormData();
      copyFileList.forEach(file => {
        this.fileList.map(item => {
          if (file.uid == item.uid) {
            formData.append('files', file);
          }
        })
      });
      // this.uploading = true;
      try {
        const result = await uploadFile(formData)

        this.$emit('handleOk')
        this.ocrVisible = false
        this.$message.success(result.msg || '上传成功')
      } catch (error) {
        this.ocrVisible = false
      }
    },
    close() {
      this.$emit('handleClose')
    },
    // ocr识别弹层关闭
    handleOcrClose() {
      this.ocrVisible = false
      this.$emit('handleOk')
    },
    handleOcrCallBack() {
      this.ocrVisible = false
      // this.$message.success('上传成功')
      this.$emit('handleOk')
    }
  }
}
</script>

<style lang="less">
.upload-modal {
  .ant-modal-body {
    padding: 70px;
  }
  .ant-modal-content {
    .modal-content {
      min-height: 123px !important;
    }
    .ant-modal-header {
      border:  none;
      padding-bottom: 0 !important;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #9B9B9B;
          margin-right: 50px;
        }


        .ok {
          background: url(../../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;

      .upload-drag {
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-upload-drag-icon {
          margin-right: 40px;
        }
      }
    }

    .cancle {
      margin-right: 20px;
    }
    .cancle:hover {
      border: 1px solid #6868CE;
      color: #6868CE;
    }
  }
}


.ant-upload-list-text {
  overflow: auto;
}

.ant-upload-list-text > div {
  height: 50px;
}

.ant-upload-list-item-name {
  width: calc(100% - 10px);
}

</style>